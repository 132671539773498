<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>流程类型</b></label><br />
            <el-select v-model="model.STEP_IDX_TYPE" :disabled="isNotChange" v-show="isShow">
              <el-option :value="1" label="采购单据" />
              <!-- <el-option :value="2" label="领用单据" /> -->
              <el-option :value="3" label="借用单据" />
            </el-select>
            <el-select v-model="model.STEP_IDX_TYPE_NAME" :disabled="isNotChange" v-show="isNotChange">

            </el-select>
          </div>

          <div class="form-group">
            <label><b>步骤号</b></label><br />
            <el-input type="number" v-model="model.STEP_IDX" :disabled="stepIdxShow||isNotChange" />
          </div>
          <div class="form-group">
            <label><b>步骤名称</b></label><br />
            <el-input v-model="model.STEP_NAME" :disabled="isNotChange" />
          </div>
          <!-- <div class="form-group">
            <label><b>表达式</b></label><br />
            <el-input v-model="model.COND_EXP" :disabled="true"/>
          </div> -->
          <div class="form-group">
            <label><b>金额条件判断</b></label><br />
            <el-input v-model="model.COND_VAL" />
          </div>
          <div class="form-group">
            <label><b>处理人员</b></label><br />
            <el-select v-model="model.TEACHERS" filterable placeholder="请选择" multiple style="width: 80%">
              <el-option v-for="item in tea.ls" :key="item.ID" :label="item.NAME" :value="item.ID">
              </el-option>
            </el-select>
          </div>
          <!--          <div class="form-group">-->
          <!--            <label><b>排序</b></label><br />-->
          <!--            <el-input-number v-model="model.SORT" :min="1"/>-->
          <!--          </div>-->
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sta: { show: false, loading: false },
        model: { TEACHERS: [] },
        tea: {
          ls: [],
        },
        isNotChange: false,
        isShow: false,
        stepIdxShow: false,
      }
    },
    methods: {
      init(model) {
        if (model != null) {
          this.stepIdxShow = true
          if (model.STEP_IDX_TYPE_NAME === "系统预设") {
            this.isNotChange = true
            this.isShow = false
          } else {
            this.isNotChange = false
            this.isShow = true
          }
        } else {
          this.isShow = true;
          this.isNotChange = false;
          this.stepIdxShow = false;
        }
        this.getTeacher();
        this.sta = { show: true, loading: false }
        this.model = Object.assign({}, model, { TEACHERS: [] });
        if (this.model.TEA_IDS) {
          this.model.TEACHERS = this.model.TEA_IDS.split(',').map(o => parseInt(o));
        }

        let self = this;
        if (this.tea.ls.length == 0) {
          this.whale.remote.getCollection({
            url: "/api/School/ORG/TeacherApi/GetList",
            completed(its) {
              self.tea.ls = its;
            }
          })
        }
      },
      submit() {
        let self = this;
        self.sta.loading = true;
        if (this.model.TEACHERS == null) {
          alert("请选择处理人员");
        }
        this.model.TEA_IDS = this.model.TEACHERS.join(',');
        let arr = [];
        for (let i = 0; i < this.tea.ls.length; i++) {
          let it = this.tea.ls[i];
          if (this.model.TEACHERS.indexOf(it.ID) >= 0) {
            arr.push(it.NAME);
          }
        }
        this.model.TEA_NAME = arr.join(',');
        this.whale.remote.getResult({
          url: "/api/School/MAT/MVConfApi/Save",
          data: self.model,
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      },
      getTeacher() {
        let self = this;
        this.whale.remote.getCollection({
          url: '/api/School/ORG/TeacherApi/GetList',
          completed(its) {
            self.tea.ls = its
          }
        })
      }
    }
  }
</script>